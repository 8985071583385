const API_DATA_LIMIT = 10;
const NFT_DATA_LIMIT = 12;
const ENV = process.env;

const ENVIRONMENT = {
  BUCKET_URL: ENV.REACT_APP_BUCKET_URL,
  ACCESSKEYID: ENV.REACT_APP_ACCESSKEYID,
  SECRETACCESSKEY: ENV.REACT_APP_SECRETACCESSKEY,
  REGION: ENV.REACT_APP_REGION,
  IMAGEURL : ENV.REACT_APP_IMAGE_URL
};
const PAGES = {
  addressable:'addressable',
  design: "design",
  user: "user",
};

const ROLES = {
  admin: "admin",
  subAdmin: "subAdmin",
};

const FORMIK_REGEX = {
  ALPHA_REGEX: /^[A-Za-z\s]+$/,
  MOBILE_NUMBER_REGEX: /^\+(?:[0-9]●?){6,16}[0-9]$/,
  PASSWORD_REGEX:
    /^.*(?=.{6})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  WHOLE_DECIMALNUMBERS_REGEX: /^[1-9]\d{0,9}(\.\d{1,2})?%?$/,
  NATURAL_NUMBERS_REGEX: /^[1-9][0-9]{0,9}$/,
  ROYALITY_REGEX: /^(?:[1-9]|[1-9][0-9]|100)$/,
  EMAIL_REGEX:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
};
const PLACEHOLDER_TEXT = {
  Nft: "Search by NFTs name",
  default: "Search by name ",
  title: "Search by name ",
  email: "Search by Email and Name",
  search: "Search by name ",
  name: "Search by name",
  city: "Select City",
};

export {
  API_DATA_LIMIT,
  PAGES,
  ROLES,
  FORMIK_REGEX,
  PLACEHOLDER_TEXT,
  NFT_DATA_LIMIT,
  ENVIRONMENT,
};
