import React from "react";
import Form from "react-bootstrap/Form";
import "./FormikControls.scss";

const Checkbox = ({ label, className }) => {
  return (
    <Form.Check
      className={`checkboxStyle ${className}`}
      type="checkbox"
      label={label}
    />
  );
};

export default Checkbox;
