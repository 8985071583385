import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../customHooks/useAuth";

const NoAuthGaurd = ({ children }) => {
  const auth = useAuth();
  return !auth ? children : <Navigate to="/admin/dashboard" />;
};

export default NoAuthGaurd;
