import React, { memo, useEffect, useState } from "react";
import "./UserManagement.scss";
import { Col, Container, Row } from "react-bootstrap";
import CommonTable from "../../../common/ui/CommonTable/CommonTable";
import { CircledEyeIcon } from "../../../../assets/images/svg/SvgIcon";
import Filter from "../../../common/Filter/Filter";
import DeActivateModal from "../../../common/ui/Modals/DeActivateModal/DeActivateModal";
import { useSelector } from "react-redux";
import { apiCallPatch, apiCallPost } from "../../../../axios/axios";
import { checkUndefiendValue, getEmailDisplay } from "../../../../utils/utils";
import {
  API_DATA_LIMIT,
  PAGES,
  PLACEHOLDER_TEXT,
} from "../../../../utils/constants";
import PaginationWrapper from "../../../common/ui/PaginationWrapper/PaginationWrapper";
import activeIcon from "../../../../assets/images/svg/user.svg";
import deactiveIcon from "../../../../assets/images/svg/blockuser.svg";
import { useNavigate } from "react-router";
import CustomSwitch from "../../../common/ui/Formik/CustomSwitch/CustomSwitch";

const UserMangemant = () => {
  // states

  const [showDeactivate, setShowDeactivate] = useState(false);
  const [userData, setUserData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [status, SetStatus] = useState("");

  // hooks
  const { user } = useSelector((state) => state.filters);
  let navigate = useNavigate();

  // functions
  const getAllUser = async () => {
    let payload = {
      search: user?.search || "",
    };
    let res = await apiCallPost(
      `/api/v1/user/${API_DATA_LIMIT}/${offset}`,
      payload,
      {},
      false,
      true
    );
    if (!res?.error) {
      setUserData(res?.data);
    }
  };
  useEffect(() => {
    getAllUser();
  }, [user, offset]);

  useEffect(() => {
    if (user) {
      getAllUser();
    }
    setOffset(1);
  }, [user]);

  const changeStatus = async (data) => {
    let payload = {
      // id: data?.userId,
      status: data?.selectedStatus,
    };
    const userId = data?.userId;
    let res = await apiCallPatch(
      `/api/v1/user/${userId}/updateStatus`,
      payload,
      {},
      true,
      true
    );
    if (!res?.error) {
      getAllUser();
      {
        setShowDeactivate(false);
      }
    }
  };

  const statusChangeHandler = (status, userId) => {
    let blockAction = status === status ? true : false;
    SetStatus(status);
    let data = {
      selectedStatus: status,
      userId: userId,
    };
    setSelectedUser(data);
    if (blockAction) {
      setShowDeactivate(true);
    } else {
      changeStatus(data);
    }
  };

  return (
    <>
      <section className="user_management">
        <Container fluid>
          <Row>
            <Col>
              <Filter

                isSearch
                // isStartdate
                // isToDate
                // isButton
                page={PAGES.user}
                isMan = {true}
                // title="Export CSV"
                placeholdertext={PLACEHOLDER_TEXT.email}
                // onClick={handleCSVFile}
              />
              <CommonTable className="user_management_table">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userData?.count > 0 ? (
                    userData?.rows?.map((item, index) => (
                      <tr key={index}>
                        <td>{offset * 10 - 10 + index + 1}</td>
                        <td>{checkUndefiendValue(item?.userName, "--")}</td>
                        <td>
                          {checkUndefiendValue(
                            getEmailDisplay(item?.email),
                            "--"
                          )}
                        </td>
                        <td>
                          {checkUndefiendValue(
                            item?.status === "ACTIVE" ? "Active" : "Deactive",
                            "--"
                          )}
                        </td>

                        {/* <td className="action-cell"></td> */}
                        <td>
                          <div className="commonBtnsGroup">
                            <span
                              onClick={() => {
                                let status =
                                  item?.status === "BLOCKED"
                                    ? "ACTIVE"
                                    : "BLOCKED";
                                statusChangeHandler(status, item?.id);
                              }}
                            >
                              <CustomSwitch
                                checked={item?.status === "BLOCKED"}
                                onChange={(newStatus) => {
                                  let status =
                                    newStatus === true ? "BLOCKED" : "ACTIVE";
                                  statusChangeHandler(status, item?.id);
                                }}
                              />
                            </span>

                            {/* <span
                            onClick={() => {
                              let status =
                                item?.status === "ACTIVE"
                                  ? "BLOCKED"
                                  : "ACTIVE";
                              statusChangeHandler(status, item?.id);
                            }}
                          >
                            {item?.status === "BLOCKED" ? (
                              <CustomSwitch />
                            ) : (
                              <CustomSwitch />
                            )}
                          </span> */}
                            <span
                              className="mt-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                // getUserProfile()
                                // appData(item?.patientId)
                                navigate("/admin/user_tryon", {
                                  state: { data: item?.id, isTryon: true },
                                });
                              }}
                            >
                              <CircledEyeIcon />
                            </span>
                          </div>
                        </td>
                        {/* </td> */}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}

                  {showDeactivate && (
                    <DeActivateModal
                      Title={`Are you sure you want to ${
                        status === "BLOCKED" ? "deactive" : "active"
                      } this user ?`}
                      show={showDeactivate}
                      onHide={() => setShowDeactivate(false)}
                      heading="Deactivate User"
                      selectedUser={selectedUser}
                      onSubmit={(data) => {
                        changeStatus(data);
                      }}
                    />
                  )}

                  {/* } */}
                </tbody>
              </CommonTable>
              {/* {userData?.count > API_DATA_LIMIT && ( */}
              <div className="page_nation">
                <PaginationWrapper
                  limit={API_DATA_LIMIT}
                  page={offset}
                  count={userData?.count}
                  onChange={(e) => setOffset(e)}
                />
              </div>
              {/* )} */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default memo(UserMangemant);
