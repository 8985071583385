import React, { memo, useState } from "react";
import CommonModal from "../CommonModal/CommonModal";
import { Form, Formik } from "formik";
import "./DeActivateModal.scss";
import { Row, Col } from "react-bootstrap";
import CommonBtn from "../../CommonBtn/CommonBtn";
import  VerifyIcon  from "../../../../../assets/images/icons/fa-solid_ban.png";

const DeActivateModal = ({ Title,show, onHide, status, onSubmit, selectedUser }) => {
  const initialValues = {
 
  };

  ;
  const submitHandler = (values) => {
    let datas = {
      ...selectedUser,
    };
    onSubmit(datas);
  };
  

  return (
    <>
      <CommonModal
        show={show}
        onHide={onHide}
        heading={Title}
        className="deActivate_modal no_btn"
        // infoImage={status === "BLOCKED" ?  VerifyIcon : ""}
        infoImageClass
      > 
        <Formik
          initialValues={initialValues}
          // validationSchema={validationSchema}
          onSubmit={submitHandler}
          name="time"
        >
          
          {(formik) => (
            <Form>
              <Row className="deactivate_row">
             
                <Col xl={12}>
                
                  <div className="btn_sec">
                    <CommonBtn
                      title="Cancel"
                      role="btn"
                      // to="/subadmin-management"
                      type="button"
                      className="subadminBtn disable-btn w-100"
                      onClick={onHide}
                    />
                    <CommonBtn
                      title="Confirm"
                      role="btn"
                      // to="/subadmin-management"
                      className="subadminBtn w-100"
                      disabled={!formik?.isValid}
                      // type="submit"
                    />
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </CommonModal>
    </>
  );
};

export default memo(DeActivateModal);
