import React from "react";

// public pages

// auth pages

const Login = React.lazy(() => import("./public/AuthPages/Login"));
const OtpVerification = React.lazy(() =>
  import("./public/AuthPages/OtpVerification")
);
const ChangePassword = React.lazy(() =>
  import("./public/AuthPages/ChangePassword")
);
const ErrorPage = React.lazy(() => import("./public/ErrorPage"));

// private pages
const Dashboard = React.lazy(() => import("./Private/Dashboard/Dashboard"));
const ProfileSetting = React.lazy(() =>
  import("./Private/ProfileSetting/ProfileSetting")
);
const Addresseable = React.lazy(() =>
  import("./Private/Addresseable/Addresseable")
);

const CreateDesign = React.lazy(() => import("./Private/DesignManagement/CreateDesign/CreateDesign"));


export {
  Login,
  OtpVerification,
  ChangePassword,
  Dashboard,
  ProfileSetting,
  Addresseable,
  ErrorPage,
  CreateDesign,
};
