import React, {useEffect } from "react";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import Customdropdown from "../Customdropdown/Customdropdown";
import iconItem from "../../../../assets/images/icons/profile-icon.svg";
import iconItem2 from "../../../../assets/images/icons/logout-icon.svg";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import useLogout from "../../../../customHooks/useLogout";
import { checkUndefiendValue, truncateName } from "../../../../utils/utils";

const Header = () => {
  const { pathname } = useLocation();
  const amountoptions = [
    {
      // to: "/admin/profile-setting",
      value: "profile-setting",
      label: "Profile Setting",
      iconItem: iconItem,
    },
    {
      // to: "/login",
      value: "logout",
      label: "Logout",
      iconItem: iconItem2,
    },
  ];


  const userData = useSelector((state) => state.user.userData);

 
  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const [logout] = useLogout();

  const dropdownClickHandler = (menu) => {
    switch (menu) {
      case "profile-setting":
        navigate("/admin/profile-setting");
        break;
      case "logout":
        logout();
        break;
      default:
        break;
    }
  };

  // const stringToLinkText = (string) => {
  //   let newStr = string.split("-").map((item) => {
  //     return item[0].toUpperCase() + item.slice(1);
  //   });
  //   return newStr.join(" ");
  // };
  const Titile = {
    "/admin/dashboard": "Dashboard",
    "/admin/design_managment": "Design Management",
    "/admin/edit_design_management" : 'Edit Design Management',
    "/admin/create_design": "Create Design",
    "/admin/user_managment": "User Management",
    "/admin/user_tryon": "User Tryon",
    "/admin/addresseable": "Addressable",
    "/admin/create_addresseable": "Create Addressable",
    "/admin/edit_create_addresseable" : "Edit Addressable"
  };
  return (
    <>
      <div className="top_header">
        <div className="heading">{Titile[pathname]}</div>
        <div className="header_right_links">
          {/* <Link to="/admin/wallet-list" className="wallet_address">
            <p>
              <span>
                <img src={wallet_icon} alt="wallet-icon" />
              </span>
              Wallet Balance:
            </p>
            <p className="balance_value">$20,000</p>
          </Link> */}
          <Customdropdown
            icon={userData?.image ? userData?.image : ""}
            title=   {checkUndefiendValue(
             userData?.userName,
              "--"
            )}
            className="header_dropdown"
            iconItem={iconItem}
            onClick={dropdownClickHandler}
            dropdownItems={amountoptions}
          />

          {/* <Button onClick={() => setBackdrop(!backdrop)} ref={toggleBtn} className="head_btn"><HamburgerIcon /></Button> */}
        </div>
      </div>
    </>
  );
};

export default Header;
