import { combineReducers } from "redux";
import loader from "../redux/loader";
import user from "../redux/user";
import filters from "../redux/filters";
import counter from "../features/counter/counterSlice";

const createRootReducer = () =>
  combineReducers({
    user,
    loader,
    filters,
    counter,
  });

export default createRootReducer;
