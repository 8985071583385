import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./LeftSidebar.scss";
import LogoMain from "../../../../assets/images/img/yeswhiteLogo.svg";
import {
  Addressable,
  DashboardIcon,
  DesignMangmentIcon,
  UserMangmentIcon,
} from "../../../../assets/images/svg/SvgIcon";
import designMangement from "../../../../assets/images/svg/DesignMangement.svg";
import userManageIcon from "../../../../assets/images/svg/userMangent.svg";
import Ads from "../../../../assets/images/img/Envwhite.svg";
import { useSelector } from "react-redux";
import Doctor from "../../../../assets/images/svg/Doctor.svg";
import RoomAssign from "../../../../assets/images/svg/RoomAssign .svg";
import { Offcanvas, Button, Image } from "react-bootstrap";
import menu from "../../../../assets/images/img/menu.png";

const LeftSidebar = ({ name, ...props }) => {
  const [Active, setActive] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { role } = useSelector((state) => state.user);

  const Headerdata = [
    {
      home_icon: <DashboardIcon />,
      title: "Dashboard",
      to: "/admin/dashboard",
    },
    {
      home_icon: (
        <>
          <img src={Ads} alt="icon" />
        </>
      ),
      title: "Addressable ",
      to: "/admin/addresseable",
    },

    {
      // home_icon: <DesignMangmentIcon />,
      home_icon: (
        <>
          <img src={designMangement} alt="icon" />
        </>
      ),
      title: "Design Management",
      to: "/admin/design_managment",
    },
    {
      // home_icon: <UserMangmentIcon />,
      home_icon: (
        <>
          <img src={userManageIcon} alt="icon" />
        </>
      ),

      title: "User Management",
      to: "/admin/user_managment",
    },
  ];
  const [active] = useState(false);

  return (
    <>
      <div className={`Admin_leftside_layout ${active ? "active" : "null"}`}>
        <div className="header_logo">
          <Link to="/">
            <img alt="logo" src={LogoMain} />
          </Link>
        </div>
        <div className="header_links">
          {/* <PerfectScrollbar> */}
          <ul>
            {Headerdata.map((data, index) => {
              return (
                data?.restrictedFor !== role && (
                  <li key={index}>
                    <NavLink to={data.to}>
                      <span>{data.home_icon}</span>
                      {data.title}
                    </NavLink>
                  </li>
                )
              );
            })}
            {/* <div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => (
                  <Form className="admin_form">
                    <FormikControls
                      defaultText={defaultSelectText}
                      optionsList={countryList}
                      control="select"
                      type="text"
                      formik={formik}
                      name="documentType"
                      variant="sidebar_select"
                    />
                  </Form>
                )}
              </Formik>
            </div> */}
          </ul>
          {/* </PerfectScrollbar> */}
        </div>
      </div>
      {/* --------------------responsive------------------------ */}

      {/* <button
        className={`toggleBtn ${active ? "active" : ""}`}
        onClick={() => setActive(!active)}
      >
        <span className="menu-line"></span>
        <span className="menu-line"></span>
        <span className="menu-line"></span>
      </button> */}
      <Button className="d-lg-none toggleBtn " onClick={handleShow}>
        <Image width={25} src={menu} alt="icon" />
      </Button>

      <Offcanvas
        className="Admin_leftside_layout "
        style={{ width: "250px", backgroundColor: "#6F7E8C" }}
        show={show}
        onHide={handleClose}
        responsive="lg"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ padding: "0", overflow: "unset" }}>
          <div>
            <Link to="/">
              <Image alt="logo" src={LogoMain} fluid />
            </Link>
          </div>
          <div className="header_links">
            {/* <PerfectScrollbar> */}
            <ul>
              {Headerdata.map((data, index) => {
                return (
                  data?.restrictedFor !== role && (
                    <li key={index}>
                      <NavLink to={data.to}>
                        <span>{data.home_icon}</span>
                        {data.title}
                      </NavLink>
                    </li>
                  )
                );
              })}
              {/* <div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => (
                  <Form className="admin_form">
                    <FormikControls
                      defaultText={defaultSelectText}
                      optionsList={countryList}
                      control="select"
                      type="text"
                      formik={formik}
                      name="documentType"
                      variant="sidebar_select"
                    />
                  </Form>
                )}
              </Formik>
            </div> */}
            </ul>
            {/* </PerfectScrollbar> */}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default LeftSidebar;
