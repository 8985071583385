import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiCallPost } from "../axios/axios";
import { setUserData } from "../redux/user";


const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.auth);
  const logout = async () => {
    let payload = {
      id : userData,
    }
    apiCallPost(
        "/api/v1/auth/logout",
        payload,
      {},
      true,
      true
    )
      .then((data) => {
        if (data && !data.error) {
          localStorage.clear();
          dispatch(setUserData(""));
          navigate("/");
          
        }
      })
      .catch((error) => {});
  };
  return [logout];
};

export default useLogout;
