import React from "react";
import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "../customHooks/useAuth";

const AuthGuard = ({ children }) => {
  const auth = useAuth();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!auth) {
      <Navigate to="/" />;
    }
  }, [pathname]);
  return auth ? children : <Navigate to="/" />;
};

export default AuthGuard;
