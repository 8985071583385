import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { PrimaryLayouts } from "../component/common/layouts";
import {
  Dashboard,
  Login,
  ProfileSetting,
  ErrorPage,
} from "../component/pages";
import NoAuthGaurd from "./NoAuthGaurd";
import AuthGuard from "./AuthGuard";
import ForgotPassword from "../component/pages/public/AuthPages/ForgotPassword";
import DesignManagement from "../component/pages/Private/DesignManagement/DesignManagement";
import Addresseable from "../component/pages/Private/Addresseable/Addresseable";
import CreateDesign from "../component/pages/Private/DesignManagement/CreateDesign/CreateDesign";
import CreateAddresseable from "../component/pages/Private/Addresseable/CreateAddresseable/CreateAddresseable";
import UserMangemant from "../component/pages/Private/UserManagemant/UserMangemant";
import UserTryon from "../component/pages/Private/UserTryon/UserTryon";
// import CreateNFTItem from "../component/pages/Private/CreateNFT/CreateNFTItem";
// import BannerManagement from "../component/pages/Private/BannerManagement/BannerManagement";

const router = createBrowserRouter([
  // public pages
  {
    path: "/",
    element: (
      <NoAuthGaurd>
        <Login />
      </NoAuthGaurd>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgot-password",
    element: (
      <NoAuthGaurd>
        <ForgotPassword />
      </NoAuthGaurd>
    ),
  },

  {
    path: "/admin",
    element: (
      <AuthGuard>
        <PrimaryLayouts />
      </AuthGuard>
    ),
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "design_managment",
        element: <DesignManagement />,
      },
      {
        path: "create_design",
        element: <CreateDesign />,
      },
      {
        path: "edit_design_management",
        element: <CreateDesign/>
      },
      {
        path: "profile-setting",
        element: <ProfileSetting />,
      },
     
      {
        path: "addresseable",
        element: <Addresseable />,
      },
      {
        path: "create_addresseable",
        element: <CreateAddresseable />,
      },
      {
        path: "edit_create_addresseable",
        element: <CreateAddresseable/>
      },
      {
        path: "user_managment",
        element: <UserMangemant/>
      },
      {
        path: "user_tryon",
        element: <UserTryon/>
      },
    ],
  },
]);

const Routes = () => {
  return (
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Routes;
