import React, { memo, useState } from "react";
import "./CreateAddresseable.scss";
import {
  BackArrow,
  CrossIcon,
  PlusIcon,
  UploadIconNew,
} from "../../../../../assets/images/svg/SvgIcon";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import { CommonBtn, FormikControls } from "../../../../common/ui";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import {
  apiCallGet,
  apiCallPatch,
  apiCallPost,
} from "../../../../../axios/axios";
import { toasts } from "../../../../common/ui/Toast/Toast";
import AWS from "aws-sdk";
import { useEffect } from "react";
import { ENVIRONMENT } from "../../../../../utils/constants";
import LoaderUi from "../../../../common/ui/Loader/LoaderUi";

const CreateAddresseable = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // States
  const [nftImage, setNftImage] = useState("");
  const [imageGallery, setImageGallery] = useState([]);
  const [data, setData] = useState([]);
  const [uploading, setUploading] = useState(false);
  

  const isEdit = location.pathname === "/admin/edit_create_addresseable";
  const itemId = location.state?.data?.id || "";
  const thumbnail = location.state?.data;

  // Formik constants
  const initialValues = {
    name: data?.name || "",
    desc: data?.description || "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required")
      .matches(/^\S*$/, "Whitespace is not allowed")
      .min(2, "Name should not be less than 2 characters")
      .max(30, "Name should not be more than 30 characters"),
    desc: Yup.string()
      .required("Description is Required")
      .min(20, "Please enter a minimum of 20 characters")
      .max(300, "Please enter a maximum of 300 characters"),
  });

  // AWS S3 configuration
  const s3 = new AWS.S3({
    accessKeyId: ENVIRONMENT.ACCESSKEYID,
    secretAccessKey: ENVIRONMENT.SECRETACCESSKEY,
    region: ENVIRONMENT.REGION,
  });

  async function handleImageUpload(file, isGallery) {
    const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"];
  
    const fileType = await getFileType(file);
  
    try {

      if (!allowedImageTypes.includes(fileType)) {
        toasts.error(
          "Unsupported file type. Please select a JPG, PNG, JPG,"
        );
        return;
      }
  
      // Set up S3 upload parameters
      const params = {
        Bucket: ENVIRONMENT.BUCKET_URL,
        Key: `${Date.now()}-${file.name}`,
        Body: file,
        ACL: "public-read",
        ContentType: fileType,
      };
  
      // Set loading state to true while uploading
      setUploading(true);
  
      // Perform the S3 upload
      await s3.putObject(params).promise();
  
      // Set loading state back to false after upload
      setUploading(false);
  
      // Construct the URL based on the S3 bucket name and key
      const imageUrl = `${ENVIRONMENT.IMAGEURL}/${params.Key}`;
  
      if (isGallery) {
        const truncatedFileType = fileType.split("/")[0];
        setImageGallery([
          ...imageGallery,
          { url: imageUrl, type: truncatedFileType },
        ]);
      } else {
        setNftImage([imageUrl]);
      }
    } catch (error) {
      console.error(error);
      setUploading(false); // Set loading state back to false in case of an error
    }
  }
  
  
  async function handleImageUploads(file, isGallery) {
    const fileType = await getFileType(file);
    try {
      // Set up S3 upload parameters
      const params = {
        Bucket: ENVIRONMENT.BUCKET_URL,
        Key: `${file.name}`,
        Body: file,
        ACL: "public-read",
        ContentType: fileType, // Depending on your access requirements
      };
      setUploading(true);
      

      // Perform the S3 upload
      await s3.putObject(params).promise();
      setUploading(false);

      // Construct the URL based on the S3 bucket name and key
      const imageUrl = `${ENVIRONMENT.IMAGEURL}/${params.Key}`;
      if (isGallery) {
        setImageGallery([...imageGallery, imageUrl]);
      } else {
        setNftImage(imageUrl);
      }
    } catch (error) {
      console.error(error);
      setUploading(false); // Set loading state back to false in case of an error
    }
  }



  async function getFileType(file) {
    if (!file) {
      console.error("File object is undefined");
      return null; // or handle the error appropriately
    }
    return file.type;
  }

  const deleteImageHandler = (index) => {
    if (index !== undefined && index !== null) {
      let newArray = [...imageGallery];
      newArray.splice(index, 1);
      setImageGallery(newArray);
    }
  };

  const handleGalleryImage = (e) => {
    if (imageGallery.length < 4) {
      handleImageUploads(e.target.files[0], true);
    } else {
      toasts.error("Cannot upload more than 4 file");
    }
  };

  // Edit Nft Item
  const handleEditNft = () => {
    if (isEdit) {
      setNftImage(thumbnail?.thumbnail);
      setImageGallery(thumbnail?.files || []);
    }
  };

  useEffect(() => {
    handleEditNft();
  }, [location.pathname]);

  const getAddressableById = async () => {
    let res = await apiCallGet(
      `/api/v1/addressable/${itemId}`,
      {},
      false,
      true
    );
    if (!res?.error) {
      setData(res?.data);
    }
  };

  useEffect(() => {
    isEdit && getAddressableById();
  }, []);

  // Form submission
  const onSubmit = async (values) => {
    if (!isEdit) {
      if (imageGallery.length > 0) {
        let payload = {
          name: values?.name,
          description: values?.desc,
          thumbnail: nftImage,
          files: imageGallery,
        };

        let res = await apiCallPost(
          `/api/v1/addressable`,
          payload,
          {},
          true,
          true
        );
        if (!res?.error) {
          navigate("/admin/addresseable");
        }
      }
    } else {
      let payload = {
        name: values?.name,
        description: values?.desc,
        thumbnail: nftImage,
        files: imageGallery,
      };
      let res = await apiCallPatch(
        `/api/v1/addressable/${itemId}`,
        payload,
        {},
        true,
        true
      );
      if (!res.error) {
        navigate("/admin/addresseable");
      }
    }
  };

  return (
    <>
      <section className="create_nft_item">
        <div className="create_nft_item_head">
          <Link to={-1}>
            <span>
              <BackArrow />
            </span>
          </Link>
          <h3>Fill the following details</h3>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {(formik) => (
            <Form>
              <Row className="mt-5">
                <Col md={12} lg={8}>
                  <Row className="p-0">
                    <Col sm={6} lg={6}>
                      <FormikControls
                        className="desc_sec"
                        label="Name"
                        placeholder="Name"
                        control="input"
                        type="text"
                        value={formik.values.name}
                        formik={formik}
                        name="name"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={12}>
                      <FormikControls
                        className="create_msg"
                        label="Description"
                        placeholder="Write Description"
                        control="textarea"
                        type="textarea"
                        rows={8}
                        value={formik.values.desc}
                        formik={formik}
                        name="desc"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg={3}>
                      <CommonBtn
                        className="mt-5"
                        title={!isEdit ? "Submit" : "Update"}
                        role="btn"
                        type="submit"
                        disabled={
                          !isEdit
                            ? !formik.isValid ||
                              !formik.dirty ||
                              imageGallery.length === 0
                            : false
                        }
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={12} lg={4}>
                  <div className="item_img">
                    <label className="form-label">Thumbnail Image</label>
                    {!nftImage ? (
                      <div className="item_img_box">
                        <input
                          className="form-control"
                          type="file"
                          accept="image/jpeg, image/png , image/jpg"
                          name="nftImage"
                          onChange={(e) =>
                            handleImageUpload(e.target.files[0], false)
                          }
                          // disabled={isEdit}
                        ></input>
                        {uploading && <LoaderUi />}
                        <UploadIconNew />
                        <h3>Drag and Drop here or</h3>
                        <h4>
                          <span>Browse File</span>
                        </h4>
                        <p>Choose only: JPG, PNG</p>
                      </div>
                    ) : (
                      <>
                        <div
                          className="block"
                          style={{
                            backgroundImage: `url(${nftImage})`,
                          }}
                        >
                          <button
                            className="removeImg"
                            type="button"
                            onClick={() => {
                              setNftImage("");
                            }}
                            // style={{ display: isEdit ? "none" : null }}
                          >
                            X
                          </button>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="item_img_sec">
                    <label className="form-label">
                      {!isEdit ? "Add File" : "Added Files "}
                    </label>
                    <div className="image_folder">
                      {imageGallery?.map((item, index) => (
                        <div key={index} className="cross_icon">
                          {/* {!isEdit ? ( */}
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteImageHandler(index)}
                            >
                              <CrossIcon />
                            </span>
                        
                          {item ? (
                            <p className="text-break">
                              {item?.split("/").pop()}
                            </p>
                          ) : null}
                        </div>
                      ))}
                      <div className="plus item_img_box">
                        <input
                          type="file"
                          accept=".json,.hash,.bundle,"
                          name="imageGallery"
                          onChange={(e) => handleGalleryImage(e)}
                        ></input>
                        {uploading && <LoaderUi />}
                        <PlusIcon />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </section>
    </>
  );
};

export default memo(CreateAddresseable);
