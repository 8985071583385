import React from "react";
import "./PrimaryLayouts.scss";
import LeftSidebar from "../../ui/LeftSidebar/LeftSidebar";
import { Header } from "../../ui";
import { Outlet } from "react-router-dom";

const PrimaryLayouts = ({ className, children, title }) => {
  return (
    <main className={`primary-layout ${className}`}>
      <div className="primary-layout__left">
        <LeftSidebar />
      </div>
      <div className="primary-layout__right">
        <Header title={title} />
        <div className="primary-layout__right__pages">
          {children}
          <Outlet />
        </div>
      </div>
    </main>
  );
};

export default PrimaryLayouts;
