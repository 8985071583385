import React, { memo } from "react";
import { Dropdown } from "react-bootstrap";
import "./customdropdown.scss";
// import icon from '../../../../assets/images/icons/apple_icon.svg'

const Customdropdown = (props) => {
  const { onClick } = props;
  return (
    <div className={`custom_dropdown ${props.className}`}>
      <Dropdown>
        <Dropdown.Toggle id="dropdown-basic">
          <div>
            {props.icon && <img src={props.icon} alt="img" />}
           Hi,{props.title}
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {props.dropdownItems?.map((item, key) => (
            <Dropdown.Item
              // href={item.to}
              key={item?.value}
              onClick={() => onClick(item?.value)}
            >
              <span>
                {props.iconItem && <img src={item.iconItem} alt="img" />}
              </span>
              {item.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default memo(Customdropdown);
