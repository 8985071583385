import React from "react";
import "./Deletemodal.scss";
import CommonModal from "../CommonModal/CommonModal";
import { DeleteIcon } from "../../../../../assets/images/svg/SvgIcon";
import CommonBtn from "../../CommonBtn/CommonBtn";

const DeleteModal = ({Title, show, onHide, onSubmit }) => {
  return (
    <>
      <CommonModal show={show} onHide={onHide} className="delete_modal">
        <div className="delete_modal_content">
          <DeleteIcon />
          <p>{Title}</p>
          <div className="btn_sec">
            <CommonBtn
              className="disable-btn me-3"
              role="btn"
              title="No"
              onClick={onHide}
            />
            <CommonBtn
              className="ms-3"
              role="btn"
              title="Yes"
              onClick={onSubmit}
            />
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default DeleteModal;
