import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { CommonBtn, CommonHeading } from "../../ui";
import { CloseIcon, Logo } from "../../../../assets/images/svg/SvgIcon";
import "./AuthLayout.scss";
import LogoMain from "../../../../assets/images/img/women.png";
import SVHLOGOText from "../../../../assets/images/img/SVHLOGOText.png";
import SVHLOGOMob from "../../../../assets/images/img/FashionLogo.svg";
import { Image } from "react-bootstrap";

const AuthLayout = ({
  isForgot,
  isLogin,
  children,
  mainImg,
  alt,
  title,
  text,
  className,
  isButton,
  isOtherMethodHide,
  bottomText,
}) => {
  return (
    <main
      className={`auth-layout before-logIn ${className}`}
      style={{ padding: 0 }}
    >
      {isButton === true ? (
        ""
      ) : (
        <CommonBtn
          role="link"
          to="/"
          iconBefore={<CloseIcon />}
          className="auth-layout__closeBtn icon border-0"
        />
      )}
      <div className="LogIn-Outer">
        <h2 className="containerbackground">
          Antier <br />
          <span>Solutions</span>
        </h2>
        <Container>
          <div className="Log-in-page">
            <Row>
              <Col lg={6}>
                <div className="loginPage__left">
                  <div className="loginPage__left__img loginPage__left__mob">
                    <span>
                      <Image src={SVHLOGOMob} alt={alt} />
                    </span>
                  </div>
                  <div className="loginPage__left__img loginPage__left__desktop">
                    <Image className="vh-100" src={LogoMain} alt={alt} fluid />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="card-login">
                  <div className="auth-card">
                    {isLogin === true ?
                      <h1 className="loginPage__right__heading">
                        Welcome Back !
                      </h1>
                      : ""}
                    {isForgot === true ?
                      <h1 className="loginPage__right__heading">
                        Forgot Password
                      </h1>
                      : ""}
                    {children}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </main>
  );
};

export default AuthLayout;
