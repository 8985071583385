import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { AuthLayout } from "../../../common/layouts";
import { CommonBtn, FormikControls } from "../../../common/ui";
import "./PagesStyle.scss";
import { EyeCloseIcon, EyeOpenIcon } from "../../../../assets/images/svg/SvgIcon";
import { FORMIK_REGEX } from "../../../../utils/constants";
import { apiCallPatch, apiCallPost } from "../../../../axios/axios";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useToggle } from "../../../../hooks/useToggle";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [step, setStep] = useState(1);
  const [isNewPassText, setIsNewPassText] = useState(false);
  const [isConfirmPassText, setIsConfirmPassText] = useState(false);
  const [token, setToken] = useState("");
  const [seconds, setSeconds] = useState(30);
  const [maxOtp, setmaxOtp] = useState(0);
  const [showPassword, togglePassword] = useToggle(false);
  const [showPasswords, togglePasswords] = useToggle(false);
 

  const [startTimer, setStartTimer] = useState(false);
  let interval;
  let initialSeconds = 30;

const isButton = false
  const setTime = async () => {
    if (startTimer) {
      interval = setInterval(async () => {
        initialSeconds -= 1;
        if (initialSeconds === 0) {
          clearInterval(interval);
          setStartTimer(false);
        }
        setSeconds(initialSeconds);
      }, 1000);
    }
  };

  const reSetTime = () => {
    setSeconds(30);
    setTime();
  };

  useEffect(() => {
    setStartTimer(true);
  }, []);
  useEffect(() => {
    if (startTimer) {
      setTime();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startTimer]);

  const navigate = useNavigate();

  const pageTitle = `${
    step === 1
      ? "Email Verification"
      : step === 2
      ? "OTP Verification"
      : "Change Password"
  }`;
  const text =
    step === 2 && `We have sent an OTP to the email address: ${email}`;
  const mainImg = step === 2 ? '' : '';

  const emailInitialValues = {
    email: "",
  };
  const emailValidationSchema = Yup.object({
    email: Yup.string()
      .required("Please enter your email")
      .email("Please enter a valid email address")
      .matches(FORMIK_REGEX.EMAIL_REGEX, "Please enter a valid email address"),
  });

  const confirmEmail = async (values) => {
    let payload = {
      email: values?.email,
      type: "ForgotPassword",
    };
    let res = await apiCallPost(
      "/api/v1/auth/forgot_password",
      payload,
      {},
      true,
      true
    );
    if (!res?.error) {
      setEmail(payload?.email);
      setmaxOtp(res?.data?.maxOtpCount);
      setStep(2);
    } else {
      setStep(1);
    }
  };

  const otpInitialValues = {
    otp: "",
  };
  const otpValidationSchema = Yup.object({
    otp: Yup.string()
      .required("Please enter OTP")
      .min(
        6,
        "The OTP (One-Time Password) should consist of a minimum of six digits."
      )
      .matches(/^[0-9]*$/, "OTP should only contain digits")
  });

  const resendOtp = async () => {
    let payload = {
      email: email,
      type: "ForgotPassword",
    };
    let res = await apiCallPost(
      "api/v1/auth/resend_otp",
      payload,
      {},
      true,
      true
    );
    if (!res?.error) {
      setmaxOtp(res?.data?.maxOtpCount);
    } else {
     
    }
  };

  const verifyOtp = async (values) => {
    let payload = {
      email: email,
      type: "ForgotPassword",
      otp: values?.otp || "",
    };
    let res = await apiCallPost(
      "/api/v1/auth/verify_otp",
      payload,
      {},
      true,
      true
    );
    if (!res?.error) {
      setToken(res?.data?.token);
      setStep(3);
    }
  };

  const setPassInitialValues = {
    password: "",
    confirmPassword: "",
  };
  const setPassValidationSchema = Yup.object({
    password: Yup.string()
      .required("Please Enter your password")
      .matches(/^\S*$/, "Whitespace is not allowed")
      .max(15)
      .matches(
        FORMIK_REGEX.PASSWORD_REGEX,
        "Must Contain 6 Characters, 1 Uppercase, 1 Lowercase, 1 Number and 1 Special Character"
      ),
    confirmPassword: Yup.string()
      .required("Please add confirm password")
      .oneOf([Yup.ref("password"), null], "Password must match"),
  });

  const setNewPassword = async (values) => {
    let payload = {
      token: token,
      password: values?.password,
      confirm_password: values?.confirmPassword,
    };
    let res = await apiCallPatch(
      "/api/v1/auth/update_password",
      payload,
      {},
      true,
      true
    );
    if (!res?.error) {
      navigate("/");
    }
  };

  const onSubmit = (values, form) => {
    switch (form) {
      case "email":
        confirmEmail(values);
        break;
      case "otp":
        verifyOtp(values);
        break;
      case "setPass":
        setNewPassword(values);
        break;
      default:
        break;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "+") {
      event.preventDefault();
      // You can display an error message here if needed
    }
  };


  return (
    <AuthLayout
      isForgot = {true}
      className="login-page change_pwd"
      mainImg={mainImg}
      alt="login_man_img"
      title={pageTitle}
      text={text}
      isButton={isButton}
    >
      {step === 1 && (
        <Formik
          initialValues={emailInitialValues}
          validationSchema={emailValidationSchema}
          onSubmit={(values) => onSubmit(values, "email")}
        >
          {(formik) => (
            <Form>
              <Row className="customInput">
                <Col xs={12}>
                  <FormikControls
                    label="Enter Email"
                    placeholder="Enter Email"
                    control="input"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    formik={formik}
                    name="email"
                  />
                </Col>
                <Col xs={12}>
                  <CommonBtn
                    title="Confirm"
                    role="btn"
                    disabled={!formik.dirty || !formik?.isValid}
                    className="w-100 login-page__loginBtn"
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
      {step === 2 && (
        <Formik
          initialValues={otpInitialValues}
          validationSchema={otpValidationSchema}
          onSubmit={(values) => onSubmit(values, "otp")}
        >
          {(formik) => (
            <Form>
              <Row>
                <Col xs={12}>
                  <FormikControls
                    label="Enter Your 6 Digit OTP"
                    control="otp-input"
                    formik={formik}
                    name="otp"
                    type="number"
                    onChange={(value) => {
                      const sanitizedValue = value.replace(/\+/g, "");
                      formik.setFieldValue("otp", sanitizedValue);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.otp}
                    onKeyPress={handleKeyPress}
                  />
                </Col>
                <Col xs={12}>
                <div className="text-center resend-text">
                  {(seconds <= 0 && maxOtp !== 0 )&& (
                    <span
                      onClick={() => {
                        setStartTimer(true);
                        reSetTime();
                        resendOtp();
                      }}
                      className="h5 fw-600 text-green d-inline-block"
                    >
                      Resend Otp
                    </span>
                  )}

                  {maxOtp > 0 ? ( 
                    <>
                      {seconds <= 0 ? (
                        <>
                          <p className="resendOtp_Link mb-4">{`You have ${maxOtp} more attempts to resend the otp.`}</p>
                        </>
                       ) : ( 
                        <p className="resendOtp_Link mb-4">{`Resend OTP available in ${seconds} seconds.`}</p>
                       )} 
                    </>
                   ) : ( 
                    <>
                      <h5 className="resendOtp_Link">{`Maximum OTP send limit exceeds`}</h5> 
                      <h5 className="resendOtp_Link">{`Please try again after an hour`}</h5>
                    </>
                   )} 
                  <span></span>
                  
                  <div  className="custom-container">
                  {maxOtp === 0 && ( 
                    <span
                      onClick={() => resendOtp()}
                      className="h6 fw-400 d-inline-block custom-span" 
                    >
                      {/* Max Otp limit exceeded */}
                    </span>
                  )} 
                  </div>
                </div>
              </Col>
                <Col xs={12}>
                  <CommonBtn
                    title="Submit"
                    role="btn"
                    className="w-100"
                    // disabled={!formik.dirty || !formik?.isValid}
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
      {step === 3 && (
        <Formik
          initialValues={setPassInitialValues}
          validationSchema={setPassValidationSchema}
          onSubmit={(values) => onSubmit(values, "setPass")}
        >
          {(formik) => (
            <Form>
              <Row>
                <Col xs={12}>
                  <div className="group_form">
                    <FormikControls
                      label="Enter New Password"
                      placeholder="Enter new password"
                      control="input"
                      type={showPasswords ? "text" : "password"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      formik={formik}
                      name="password"
                      rightIconClick={() => togglePasswords()}
                      rightIcon={showPasswords ? <EyeOpenIcon /> : <EyeCloseIcon />}
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="group_form">
                    <FormikControls
                      label="Confirm Password"
                      placeholder="Re-enter Your Password"
                      control="input"
                      type={showPassword ? "text" : "password"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.confirmPassword}
                      formik={formik}
                      name="confirmPassword"
                      rightIconClick={() => togglePassword()}
                      rightIcon={showPassword ? <EyeOpenIcon /> : <EyeCloseIcon />}
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <CommonBtn
                    title="Confirm"
                    role="btn"
                    disabled={!formik.dirty || !formik?.isValid}
                    className="w-100 login-page__loginBtn"
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </AuthLayout>
  );
};

export default ForgotPassword;
