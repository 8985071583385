import React, { memo, useEffect, useState } from "react";
import "./UserTryon.scss";
import { Col, Container, Form, Row } from "react-bootstrap";
import CommonTable from "../../../common/ui/CommonTable/CommonTable";
import {
  BackArrow,
  BinIcon,
  CircledEyeIcon,
} from "../../../../assets/images/svg/SvgIcon";
import CustomPagination from "../../../common/ui/CustomPagination/CustomPagination";
import Filter from "../../../common/Filter/Filter";
import UserDetailModal from "../../../common/ui/Modals/UserDetailModal/UserDetailModal";
import DeActivateModal from "../../../common/ui/Modals/DeActivateModal/DeActivateModal";
import { useDispatch, useSelector } from "react-redux";
import { apiCallGet, apiCallPatch, apiCallPost } from "../../../../axios/axios";
import {
  Address,
  checkUndefiendValue,
  getEmailDisplay,
} from "../../../../utils/utils";
import {
  API_DATA_LIMIT,
  DATA_LIMIT,
  PAGES,
  PLACEHOLDER_TEXT,
} from "../../../../utils/constants";
import PaginationWrapper from "../../../common/ui/PaginationWrapper/PaginationWrapper";
import Customdropdown from "../../../common/ui/Customdropdown/Customdropdown";
import { FormikControls } from "../../../common/ui";
import BootstrapSelect from "../../../common/ui/Formik/BootstrapSelect/BootstrapSelect";
import activeIcon from "../../../../assets/images/icons/Frame (6).png";
import deactiveIcon from "../../../../assets/images/icons/Frame (2).png";
import { saveAs } from "file-saver";
import CustomTooltip from "../../../common/ui/CustomTooltip/CustomTooltip";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const UserTryon = () => {
  // states
  const [userData, setUserData] = useState([]);
  const [offset, setOffset] = useState(1);
  // hooks
  const location = useLocation();
  const userId = location.state?.data || "";
  const isTryon = location.state?.isTryon || "";
  console.log("userId", userId);
  // functions
  const getUserTryon = async () => {
    let res = await apiCallPost(
      `/api/v1/user/tryon/${userId}/${API_DATA_LIMIT}/${offset}`,
      {},
      true,
      false
    );
    if (!res?.error) {
      setUserData(res?.data);
    }
  };
  useEffect(() => {
    if (isTryon) {
      getUserTryon();
    }
  }, [offset]);

  return (
    <>
      <Link to={-1}>
        <span className="back-button">
          <BackArrow />
          Back
        </span>
      </Link>
      <br />
      <section className="user_management">
        <Container fluid>
          <Row>
            <Col>
              <CommonTable className="user_management_table">
                <thead>
                  <tr>
                    <th>Serial No.</th>
                    <th>User Name</th>
                    <th>Design Name</th>
                    <th>Category</th>
                    <th>Size</th>
                  </tr>
                </thead>
                <tbody>
                  {userData?.count > 0 ? (
                    userData?.rows?.map((item, index) => (
                      <tr key={index}>
                        <td>{offset * 10 - 10 + index + 1}</td>
                        <td>
                          {checkUndefiendValue(item?.users?.userName, "--")}
                        </td>
                        <td>
                          {checkUndefiendValue(item?.designs?.name, "--")}
                        </td>
                        <td>
                          {checkUndefiendValue(item?.designs?.category, "--")}
                        </td>
                        <td>
                          {checkUndefiendValue(item?.designs?.size, "--")}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={10} style={{ textAlign: "center" }}>
                        No data found
                      </td>
                    </tr>
                  )}
                </tbody>
              </CommonTable>
              {/* {userData?.count > API_DATA_LIMIT && ( */}
              <div className="page_nation">
                <PaginationWrapper
                  limit={API_DATA_LIMIT}
                  page={offset}
                  count={userData?.count}
                  onChange={(e) => setOffset(e)}
                />
              </div>
              {/* )} */}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default memo(UserTryon);
