import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const filtersSlice = createSlice({
  name: "filters",
  initialState: initialState,
  reducers: {
    setFilters: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export const { setFilters } = filtersSlice.actions;

export default filtersSlice.reducer;
