import React from "react";
import "./LoaderStyle.scss";

const LoaderUi = () => {
  return (
    <div className="loader-outer">
      <div className="loader-animation"></div>
    </div>
  );
};

export default LoaderUi;
