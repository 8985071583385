import React, { useEffect, useState } from "react";
import {
  BinIcon,
  EditIcon,
  EditPencilIcon,
} from "../../../../assets/images/svg/SvgIcon";
import Filter from "../../../common/Filter/Filter";
import CommonTable from "../../../common/ui/CommonTable/CommonTable";
import "./Addresseable.scss";
import DeleteModal from "../../../common/ui/Modals/DeleteModal/DeleteModal";
import { useSelector } from "react-redux";
import {
  apiCallDelete,
  apiCallPatch,
  apiCallPost,
} from "../../../../axios/axios";
import {
  API_DATA_LIMIT,
  PAGES,
  PLACEHOLDER_TEXT,
} from "../../../../utils/constants";
import { checkUndefiendValue, getStatusLabel, truncateStringToWords } from "../../../../utils/utils";
import PaginationWrapper from "../../../common/ui/PaginationWrapper/PaginationWrapper";
import activeIcon from "../../../../assets/images/svg/user.svg";
import deactiveIcon from "../../../../assets/images/svg/blockuser.svg";
import DeActivateModal from "../../../common/ui/Modals/DeActivateModal/DeActivateModal";
import { Link } from "react-router-dom";
import EditNew from "../../../../assets/images/svg/edit.svg";
import DeleteIcon from "../../../../assets/images/svg/delete.svg";
import CustomSwitch from "../../../common/ui/Formik/CustomSwitch/CustomSwitch";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Addresseable = () => {
  // states
  const [addressableData, setAddressableData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [itemToDelete, setItemToDelete] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDeactivate, setShowDeactivate] = useState(false);
  const [status, SetStatus] = useState("");

  // hooks
  const { addressable } = useSelector((state) => state.filters);
  // get addressable
  const getAddresseableList = async () => {
    let payload = {
      search: addressable?.search || "",
    };
    let res = await apiCallPost(
      `/api/v1/addressable/${API_DATA_LIMIT}/${offset}`,
      payload,
      {},
      false,
      true
    );
    if (!res?.error) {
      setAddressableData(res?.data);
    }
  };

  useEffect(() => {
    getAddresseableList();
  }, [addressable, offset]);
  useEffect(() => {
    if (addressable) {
      getAddresseableList();
    }
    setOffset(1);
  }, [addressable]);

  // delete blog
  const deleteAddressable = async () => {
    let res = await apiCallDelete(
      `/api/v1/addressable/${itemToDelete}`,
      {},
      {},
      true,
      true
    );
    if (!res?.error) {
      setItemToDelete("");
      getAddresseableList();
    }
  };
  const deleteHandler = (item) => setItemToDelete(item);

  const submitCallback = () => {
    deleteAddressable();
  };

  const statusChangeHandler = (status, userId) => {
    let blockAction = status === status ? true : false;
    SetStatus(status);
    let data = {
      selectedStatus: status,
      userId: userId,
    };
    setSelectedUser(data);
    if (blockAction) {
      setShowDeactivate(true);
    } else {
      changeStatus(data);
    }
  };

  const changeStatus = async (data) => {
    let payload = {
      // id: data?.userId,
      status: data?.selectedStatus,
    };
    const userId = data?.userId;
    let res = await apiCallPatch(
      `/api/v1/addressable/${userId}`,
      payload,
      {},
      true,
      true
    );
    if (!res?.error) {
      getAddresseableList();
      {
        setShowDeactivate(false);
      }
    }
  };

  return (
    <div className="Blog_management">
      <Filter
        isSearch
        isButton
        to={"/admin/create_addresseable"}
        title={<p>Add Addressable</p>}
        page={PAGES.addressable}
        placeholdertext={PLACEHOLDER_TEXT.title}
      />
      <CommonTable className="user_management_table">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Name</th>
            <th>Description</th>
            <th>Status</th>
            <th style={{ width: "330" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {addressableData?.count > 0 ? (
            addressableData?.rows?.map((item, index) => (
              <tr key={index}>
                <td>{offset * 10 - 10 + index + 1}</td>
                <td> {checkUndefiendValue(item?.name, "--")}</td>
                <td>
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="tooltip" style={{ fontSize: 14 }}>
                        {checkUndefiendValue(item.description, "--")}
                      </Tooltip>
                    }
                  >
                    <p className="mb-3  text-wrap">
                      {truncateStringToWords(checkUndefiendValue(item.description, "--") , 5)}
                    </p>
                  </OverlayTrigger>
                </td>
                <td>
                  {checkUndefiendValue(
                    item?.status === "ACTIVE" ? "Active" : "Deactive",
                    "--"
                  )}
                </td>
                <td>
                  <div className="commonBtnsGroup">
                    <span
                      onClick={() => {
                        let status =
                          item?.status === "BLOCKED" ? "ACTIVE" : "BLOCKED";
                        statusChangeHandler(status, item?.id);
                      }}
                    >
                      <CustomSwitch
                        checked={item?.status === "BLOCKED"}
                        onChange={(newStatus) => {
                          let status =
                            newStatus === true ? "BLOCKED" : "ACTIVE";
                          statusChangeHandler(status, item?.id);
                        }}
                      />
                    </span>
                    <span
                      //  className="me-5"
                      onClick={() => deleteHandler(item?.id)}
                    >
                      <img src={DeleteIcon} alt="icon" />
                    </span>
                    <span>
                      <Link
                        to="/admin/edit_create_addresseable"
                        state={{ data: item }}
                      >
                        <img src={EditNew} alt="icon" />
                      </Link>
                    </span>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7} style={{ textAlign: "center" }}>
                No data found
              </td>
            </tr>
          )}
        </tbody>
      </CommonTable>
      {/* <UpdateBlogModal show={showUpdate} onHide={() => setShowUpdate(false)} /> */}

      <DeleteModal
        Title={"Are you sure you want to delete this Env ?"}
        show={itemToDelete}
        onHide={() => setItemToDelete("")}
        onSubmit={submitCallback}
      />

      {/* {showDeactivate && (
        <DeActivateModal
          status={status}
          Title={`Are you sure you want to ${
            status === "BLOCKED" ? "deactive" : "active"
          } this addressable`}
          show={showDeactivate}
          onHide={() => setShowDeactivate(false)}
          heading="Deactivate User"
          selectedUser={selectedUser}
          onSubmit={(data) => {
            changeStatus(data);
          }}
        />
      )} */}

      {showDeactivate && (
        <DeActivateModal
          status={status}
          Title={`Are you sure you want to ${
            status === "BLOCKED" ? "deactive" : "active"
          } this Env ?`}
          show={showDeactivate}
          onHide={() => setShowDeactivate(false)}
          heading="Deactivate User"
          selectedUser={selectedUser}
          onSubmit={(data) => {
            changeStatus(data);
          }}
        />
      )}

      {/* {addressableData?.count > API_DATA_LIMIT && ( */}
      <div className="page_nation">
        <PaginationWrapper
          limit={API_DATA_LIMIT}
          page={offset}
          count={addressableData?.count}
          onChange={(e) => setOffset(e)}
        />
      </div>
      {/* // )} */}
      {/* <div className="page_nation">
       <CustomPagination />
     </div> */}
    </div>
  );
};

export default Addresseable;
