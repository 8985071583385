import React from "react";
import './PaginationWrapper.scss'
import Pagination from "react-js-pagination";

const PaginationWrapper = (props) => {
  const { limit, page, count, onChange } = props;
  return (
    <Pagination
      className="paginationStyle"
      activePage={page}
      itemsCountPerPage={limit}
      totalItemsCount={count}
      pageRangeDisplayed={5}
      onChange={onChange}
      itemClass="page-item"
      linkClass="page-link"
      hideFirstLastPages={true}
    />
  );
};

export default PaginationWrapper;
