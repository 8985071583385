import axios from "axios";
import { storeInstance } from "..";
import { toasts } from "../component/common/ui/Toast/Toast";
import { setLoader } from "../redux/loader";
import { decryption, encryption } from "../utils/utils";

const BASE_URL = process.env.REACT_APP_API_HOST;
const IS_ENCRYPTION = process.env.IS_ENCRYPTION;

//axios instance
export const axiosApi = axios.create({
  baseURL: BASE_URL,
});
//axios interceptors
axiosApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//formal url
function formatUrl(url, params) {
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : "";
  return `${url}${params}`;
}

//handle success and error
function handleError(error) {
  if (
    error?.response?.status &&
    (error?.response?.status === 403 || error?.response?.status === 401)
  ) {
    toasts.error("Please re-login, last login session expired.");
    localStorage.clear();
  } else {
    error?.data?.message && toasts.error(error.data.message);
    error?.response?.data?.message && toasts.error(error.response.data.message);
  }
}

function handleSuccess(res) {
  if (res?.status === 200 || res?.status === 201) {
    res?.message && toasts?.success(res?.message);
    res?.data?.message && toasts?.success(res?.data?.message);
  }
  if (res.status === 403 || res.status === 400) {
    res.message && toasts.warning(res.message);
  }
}

//encryption and decryption of payload
const encryptionFilter = (data) => {
  if (!data?.entries && data?.entries === undefined) {
    let encD = IS_ENCRYPTION ? encryption(JSON.stringify(data)) : data;
    return IS_ENCRYPTION ? { reqData: encD } : data;
  } else {
    return data;
  }
};
const decryptionFilter = (data) => {
  if (data && data?.resData && typeof data?.resData == "string") {
    let decD = IS_ENCRYPTION ? JSON.parse(decryption(data?.resData)) : data;
    return IS_ENCRYPTION ? decD : data;
  } else {
    return data;
  }
};

//api methods
export const apiCallGet = async (url, params = {}, toastOn, loader = false) => {
  // let dataFiltered = await encryptionFilter(data);
  loader && storeInstance.dispatch(setLoader(true));
  const resp = await axiosApi
    .get(formatUrl(url, params), {})
    .then(async (res) => {
      loader && storeInstance.dispatch(setLoader(false));
      let decryptData = decryptionFilter(res?.data);

      if (toastOn === true) {
        handleSuccess(decryptData);
      }
      return decryptData;
    })
    .catch((error) => {
      loader && storeInstance.dispatch(setLoader(false));
      let decryptData = decryptionFilter(error?.response?.data);
      if (error?.response?.status && error?.response?.status === 401) {
        toasts.error("Please re-login, last login session expired.");
        localStorage.clear();
      }
      if (
        (decryptData && decryptData?.message) ||
        decryptData?.message === "Network Error"
      ) {
        toasts.error(decryptData?.message);
      }
      return decryptData;
    });

  return resp;
};
export const apiCallPost = async (
  url,
  data,
  params = {},
  toastOn,
  loader = false
) => {
  loader && storeInstance.dispatch(setLoader(true));
  let dataFiltered = await encryptionFilter(data);
  try {
    const resp = await axiosApi.post(formatUrl(url, params), dataFiltered, {});
    let decryptData = decryptionFilter(resp?.data);

    if (toastOn === true) {
      handleSuccess(decryptData);
    }

    loader && storeInstance.dispatch(setLoader(false));
    return decryptData;
  } catch (error) {
    loader && storeInstance.dispatch(setLoader(false));
    let decryptData = decryptionFilter(error?.response?.data);

    if (error?.response?.status && error?.response?.status === 401) {
      toasts.error("Please re-login, last login session expired.");
      localStorage.clear();
    }

    if (decryptData?.message || decryptData?.message === "Network Error") {
      toasts.error(decryptData?.message);
    }

    return decryptData;
  }
};
export const apiCallPatch = async (
  url,
  data,
  params = {},
  toastOn,
  loader = false
) => {
  loader && storeInstance.dispatch(setLoader(true));
  let dataFiltered = await encryptionFilter(data);
  const resp = await axiosApi
    .patch(formatUrl(url, params), dataFiltered)
    .then(async (res) => {
      let decryptData = decryptionFilter(res?.data);

      if (toastOn === true) {
        handleSuccess(decryptData);
      }
      loader && storeInstance.dispatch(setLoader(false));
      return decryptData;
    })
    .catch((error) => {
      loader && storeInstance.dispatch(setLoader(false));
      let decryptData = decryptionFilter(error?.response?.data);
      if (error?.response?.status && error?.response?.status === 401) {
        toasts.error("Please re-login, last login session expired.");
        localStorage.clear();
      }
      if (
        (decryptData && decryptData?.message) ||
        decryptData?.message === "Network Error"
      ) {
        toasts.error(decryptData?.message);
      }
      return decryptData;
    });

  return resp;
};
export const apiCallDelete = async (
  url,
  data,
  params = {},
  toastOn,
  loader = false
) => {
  loader && storeInstance.dispatch(setLoader(true));
  let dataFiltered = await encryptionFilter(data);
  try {
    const resp = await axiosApi.delete(formatUrl(url, params), dataFiltered,{}
 );
    let decryptData = decryptionFilter(resp?.data);

    if (toastOn === true) {
      handleSuccess(decryptData);
    }

    loader && storeInstance.dispatch(setLoader(false));
    return decryptData;
  } catch (error) {
    loader && storeInstance.dispatch(setLoader(false));
    let decryptData = decryptionFilter(error?.response?.data);

    if (error?.response?.status && error?.response?.status === 401) {
      toasts.error("Please re-login, last login session expired.");
      localStorage.clear();
    }

    if (decryptData?.message || decryptData?.message === "Network Error") {
      toasts.error(decryptData?.message);
    }

    return decryptData;
  }
};

// hotel instance and methods
const apiHotelInstance = axios.create({
  baseURL: "https://hotelapi.aos-dev.com",
  headers: {
    "Content-Type": "application/json",
  },
});

export const apiHotelGet = async (url, params, toastOn, loader = false) => {
  loader && storeInstance.dispatch(setLoader(true));
  const resp = await apiHotelInstance
    .get(formatUrl(url, params), {})
    .then(async (res) => {
      loader && storeInstance.dispatch(setLoader(false));
      let decryptData = decryptionFilter(res?.data);

      if (toastOn === true) {
        handleSuccess(decryptData);
      }
      return decryptData;
    })
    .catch((error) => {
      loader && storeInstance.dispatch(setLoader(false));
      let decryptData = decryptionFilter(error?.response?.data);
      if (error?.response?.status && error?.response?.status === 401) {
        toasts.error("Please re-login, last login session expired.");
        localStorage.clear();
      }
      if (
        (decryptData && decryptData?.message) ||
        decryptData?.message === "Network Error"
      ) {
        toasts.error(decryptData?.message);
      }
      return decryptData;
    });

  return resp;
};
